import React, { useEffect } from "react";
import { useState } from "react";
import { Pagination, Button, Row, Col, Typography, notification, Spin, Card, Input } from "antd";

import DashboardLayout from "../../layout/dashboardLayout/DashboardLayout";
import TreatmentCard from "./component/TreatmentCard";
import TreatmentCardStep2 from "./component/TreatmentCardStep2";
import ModalComponent from "./component/Modal";
import { controller } from "./controller";
import "./style.css";
import active from '../../assets/icons/active.svg';
import completed from '../../assets/icons/completed.svg';
import chart from '../../assets/icons/chart.svg';
import search from '../../assets/icons/input-affix.jpg';


const { Title } = Typography;

const options = [
  { value: "not_approved", label: "New" },
  { value: "approved", label: "Approved" },
];

function TreatmentPlans() {
  const [mode, setMode] = useState("not_approved");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState();
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [prodata, setProData] = useState([]);
  const [list, setList] = useState([]);
  const [filter, setFilter] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentStep, setCurrentStep] = useState(1); // Added state for tracking the current step
  const [selectedPatient, setSelectedPatient] = useState(null); // State for selected patient
  const [searchQuery2, setSearchQuery2] = useState("");
  const [current, setCurrent] = useState(1)
  const [page_size, setPage_size] = useState(0)



  const handleSelectChangeMode = async (e) => {
    const value = e.target.value;
    setMode(value);
    setLoading(true);
    try {
      const response = await controller.getTreatmentPlans(0, value);

      if (response.status < 250) {
        setData(response.json);
      }
    } catch (e) {
      // notification.error({
      //   message: "Error",
      //   description: "Server error.",
      //   placement: 'bottomRight',
      // });
    }
    setLoading(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleReadDataNoLoading = async () => {
    try {
      const response = await controller.getTreatmentPlans(mode, searchQuery2);

      if (response.status < 250) {
        setData1(response.json);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleReadData = async () => {
    setLoading(true);
    try {
      const response = await controller.getTreatmentPlans(mode, searchQuery2, current);
  
      if (response.status < 250) {
        const data = await response.json;  // Properly parsing the JSON
        setData(data);
        setPage_size(data.count);           // Accessing count from the parsed data
      }
    } catch (e) {
      // notification.error({
      //   message: "Error",
      //   description: "Server error.",
      //   placement: 'bottomRight',
      // });
    }
    setLoading(false);
  };
  

  const handleReadProcedure = async () => {
    try {
      const response = await controller.getProcedure(0);

      if (response.status < 250) {
        setProData(response.json);
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Server error.",
        placement: 'bottomRight',
      });
    }
  };

  const handleSearch = (e) => {
    setSearchQuery2(e.target.value);
  };

  const handleReadMember = async () => {
    try {
      const response = await controller.getMemberList(0);

      if (response.status < 250) {
        setList(response.json);
      }
    } catch (e) {
      // notification.error({
      //   message: "Error",
      //   description: "Server error.",
      //   placement: 'bottomRight',
      // });
    }
  };

  const handlePostNoteAndImage = async (note, imageFile) => {
    try {
      const response = await controller.postNoteAndImage(note, imageFile);
      console.log(response);
    } catch (error) {
      console.error("Error posting note and image:", error);
    }
  };

  const handleReadTreatment = async () => {
    try {
      const [description, procedureCode] = searchQuery.split(",");

      const response = await controller.getTreatmentList(
        0,
        description.trim(),
        procedureCode.trim()
      );

      if (response.status < 250) {
        setFilter(response.json);
      }
    } catch (e) {
      // notification.error({
      //   message: "Error",
      //   description: "Server error.",
      //   placement: 'bottomRight',
      // });
    }
  };

  const handlePageChange = async (page) => {
    setCurrent(page)
  }

  const onBack = () => {
    setCurrentStep(1)
  }

  const updateDataUpdatePriority = () => {
    handleReadDataNoLoading();
    handleReadData()
  };

  const updateData = () => {
    handleReadDataNoLoading();
    handleReadProcedure();
    handleReadMember();
    handleReadData();
    handleReadTreatment();
  };

  useEffect(() => {
    handleReadData();
    console.log(data);
  }, [searchQuery2, current]);

  useEffect(() => {
    handleReadProcedure();
    console.log(prodata);
  }, []);

  useEffect(() => {
    handleReadMember();
    console.log(list);
  }, []);

  useEffect(() => {
    handleReadTreatment();
    console.log(filter);
  }, [searchQuery]);

  return (
    <>
      <ModalComponent
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        list={list}
        data1={data}
        filter={filter}
        setSearchQuery={setSearchQuery}
      />
      <DashboardLayout>
        <div className="top-margin-large margin-left-small">
          <div className="flex-row-space-between-marginBottom-20">
            {currentStep === 1 && (
              <Title level={4}>Patient's Treatment Plans</Title>
            )}
            {mode === "not_approved" && currentStep === 1 ? (
              <Button
                className="button-primary-small-width"
                style={{ width: 154, height: 34 }}
                type="primary"
                onClick={showModal}
              >
                Add
              </Button>
            ) : (
              <Button
                // className="button-primary-small-width"
                // type="primary"
                style={{ border: '1px solid #6B43B5', color: '#6B43B5', marginLeft: 'auto', width: 154, height: 34 }}
                onClick={onBack}
              >
                Back
              </Button>
            )
            }
          </div>
          {currentStep === 1 && (
            <Card style={{ width: '100%', marginBottom: 30 }}>
              <Row gutter={[16, 16]} className="flex-row-evenlyy">
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div style={{ height: 121, display: 'flex', alignItems: 'center', flexDirection: 'row', background: 'rgba(223, 218, 255, 0.7)', borderRadius: '8px' }}>
                    <div className='circle' style={{ marginLeft: 20 }}>
                      <img className='icon-center' src={active} alt='' />
                    </div>
                    <div style={{ marginLeft: 25 }}>
                      <div style={{ fontSize: 20, fontWeight: 400, color: '#4D3280', marginBottom: 10 }}>Active</div>
                      <div style={{ fontSize: 24, color: "#4D3280", fontWeight: 600 }}>
                        {data ? data.active_plans : ''}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div style={{ height: 121, display: 'flex', alignItems: 'center', flexDirection: 'row', background: 'rgba(223, 218, 255, 0.7)', borderRadius: '8px' }}>
                    <div className='circle' style={{ marginLeft: 20 }}>
                      <img className='icon-center' src={completed} alt='' />
                    </div>
                    <div style={{ marginLeft: 25 }}>
                      <div style={{ fontSize: 20, fontWeight: 400, color: '#4D3280', marginBottom: 10 }}>Completed</div>
                      <div style={{ fontSize: 24, color: "#4D3280", fontWeight: 600 }}>
                        {data ? data.completed : ''}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div style={{ height: 121, display: 'flex', alignItems: 'center', flexDirection: 'row', background: 'rgba(223, 218, 255, 0.7)', borderRadius: '8px' }}>
                    <div className='circle' style={{ marginLeft: 20 }}>
                      <img className='icon-center' src={chart} alt='' />
                    </div>
                    <div style={{ marginLeft: 25 }}>
                      <div style={{ fontSize: 20, fontWeight: 400, color: '#4D3280', marginBottom: 10 }}>Conversion</div>
                      <div style={{ fontSize: 24, color: "#4D3280", fontWeight: 600 }}>
                        {data ? data.conversion : ''}%
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          )}
          {currentStep === 1 && (
            <Col xs={24} lg={23.2} md={23.2}>
              <Card>
                <Input
                  onChange={handleSearch}
                  value={searchQuery2}
                  size="middle"
                  placeholder="Search patient"
                  prefix={<img src={search} alt="" />}
                  style={{ marginBottom: '16px', height: 37, width: 209, border: '1px solid #6B43B5', borderRadius: 30 }}
                />
                {loading ? (
                  <Row justify={"center"}>
                    <Spin />
                  </Row>
                ) : (
                  data.results && data.results.map((item) => (
                    <div className="mb" key={item._id}>
                      <TreatmentCard
                        updateData={updateData}
                        updateDataUpdatePriority={updateDataUpdatePriority}
                        data={item}
                        postNoteAndImage={handlePostNoteAndImage}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep} // Passing the setter function as a prop
                        setSelectedPatient={setSelectedPatient}
                        selectedPatient={selectedPatient} // Passing the setter function as a prop
                      />
                    </div>
                  ))
                )}
              </Card>
                <Row type='flex' justify='center' className='mt15'>
                  <Pagination
                    showSizeChanger={false}
                    hideOnSinglePage={true}
                    current={current}
                    total={page_size}
                    onChange={handlePageChange}
                    className="paginator"
                    size="small"
                  />
                </Row>
            </Col>
          )}
          {currentStep === 2 && selectedPatient && (
            <Col xs={24} lg={23.2} md={23.2}>
              <TreatmentCardStep2
                updateData={updateData}
                updateDataUpdatePriority={updateDataUpdatePriority}
                data={selectedPatient} // Pass the selected patient data
                postNoteAndImage={handlePostNoteAndImage}
                setSelectedPatient={setSelectedPatient}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
              />
            </Col>
          )}
        </div>
      </DashboardLayout>
    </>
  );
}

export default TreatmentPlans;
