const local = {
  googleCaptch: {
    code: "6LcgxK0UAAAAAH9gzsILRr82KZSLrBcry9RynMn4"
  },
  server: {
    // type: "production"
    type: 'staging'
    /*
      Two type:
      1. production
      2. staging
    */
  },
  apiGateway: {
     URL: "https://api.smilepass.com"
    // URL: "https://be-staging.smilepass.com"
    // URL: "http://157.230.96.118"
  },
  mediaGateway: {
    URL: "https://api.smilepass.com"
    // URL: "https://be-staging.smilepass.com"
    // URL: "http://157.230.96.118"
  },
  stripe: {
    key: 'pk_test_Osu2iYG3m8zmTD1xI1vljmJN00jDeQAy6a'
  },
};

const staging = {
  googleCaptch: {
    code: "6LcgxK0UAAAAAH9gzsILRr82KZSLrBcry9RynMn4"
  },
  server: {
    type: "production"
    /*
      Two type:
      1. type: "production"
      2. type: "production"
    */
  },
  apiGateway: {
     URL: "https://api.smilepass.com"
    // URL: "https://be-staging.smilepass.com"
    // URL: "http://157.230.96.118"
  },
  mediaGateway: {
    URL: "https://api.smilepass.com"
    // URL: "https://be-staging.smilepass.com"
    // URL: "http://157.230.96.118"
  },
  stripe: {
    key: 'pk_test_Osu2iYG3m8zmTD1xI1vljmJN00jDeQAy6a'
  },
};

const production = {
  googleCaptch: {
    code: "6LcgxK0UAAAAAH9gzsILRr82KZSLrBcry9RynMn4"
  },
  server: {
    type: "production"
    /*
      Two type:
      1. production
      2. staging
    */
  },
  apiGateway: {
     URL: "https://api.smilepass.com"
    // URL: "https://be-staging.smilepass.com"
    // URL: "http://157.230.96.118"
  },
  mediaGateway: {
    URL: "https://api.smilepass.com"
    // URL: "https://be-staging.smilepass.com"
    // URL: "http://157.230.96.118"
  },
  stripe: {
    URL: "https://api.smilepass.com"
    // URL: "https://be-staging.smilepass.com"
    // URL: "http://157.230.96.118"
  },
}


const config = process.env.REACT_APP_DEPLOYMENT === 'production' ? production : ((process.env.REACT_APP_DEPLOYMENT === 'staging') ? staging : local);

export default {
  someCommonConfig: '',
  ...config
};






