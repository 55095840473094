import React, { useEffect, useState } from "react";
import {
    Avatar,
    Button,
    Col,
    Card,
    Typography,
} from "antd";

// icons
import up from '../../../assets/icons/arrow-up.png'
import down from '../../../assets/icons/arrow-down.png'

import "../style.css";
import ModalStep3 from './ModalStep3'

// variables
const { Meta } = Card;

const TreatmentCard = (props) => {
    const [isExpanded, setisExpanded] = useState(false);
    const [item, setitems] = useState([]);
    const [isModalVisible1, setIsModalVisible1] = useState(false);

    useEffect(() => {
        if (props.data) {
            setitems(props.data)
        }
    }, [props.data]);

    const handleSuccessAddToServer = () => {
        props.updateData()
    }

    const handleClick = () => {
        props.updateData();
        props.setCurrentStep(2); // Set the current step to 2
        props.setSelectedPatient(props.data); // Set the selected patient data
        localStorage.setItem("patient_id", props.data.id)
    };

    const handleClose = () => {
        setisExpanded(false);
        props.updateData()
    };

    return (
        <>
            <ModalStep3
                isModalVisible={isModalVisible1}
                setIsModalVisible={setIsModalVisible1}
                selectedMember={item}
                // handleSuccessAddToServer={handleSuccessAddToServer}
            />
            <Col xs={24} lg={23.5} md={23.5}>
                <Card className="card-size">
                    <div className="flex-row-space-between">
                        <div className="avatar-meta-container1">
                            <div className="avatar-meta-width">
                                {/* <Avatar size={70} shape="circle" src={item.profile_picture} /> */}
                                <Meta
                                    className="meta-align-left"
                                    title={item.first_name + " " + item.last_name}
                                    // description="john91"
                                />
                            </div>
                            <div className="meta-width">
                                <Meta
                                    className="meta-card2"
                                    title={`${item.updated_plans} New Treatments`}
                                    // description = {Array.isArray(item.treatment_plans) && item.treatment_plans.length > 0 && item.treatment_plans.map(plan => (
                                    //     <div key={plan.id} style={{display: 'flex', flexDirection: 'row'}}>
                                    //       {plan.name},
                                    //     </div>
                                    // ))}
                                />
                            </div>
                        </div>
                        <div className="meta-card">
                            {isExpanded ? (
                                <Button
                                    className="details-button-color"
                                    type="text"
                                    onClick={handleClose}
                                >
                                    <span className="size-16">Details</span>
                                    <img src={up} alt="" style={{ marginLeft: '8px' }} />
                                </Button>
                            ) : (
                                <Button
                                    className="details-button-color"
                                    type="text"
                                    onClick={handleClick}
                                >
                                    <span className="size-16">Details</span>
                                    <img src={down} alt="" style={{ marginLeft: '8px' }} />
                                </Button>
                            )}
                        </div>
                    </div>
                </Card>
            </Col >
        </>
    )
}

export default TreatmentCard;
