import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Table,
    Input,
    Upload,
    Typography,
    Space,
    notification,
    Spin,
    Card,
    Row
} from "antd";

// icons
import check from '../../../assets/icons/check.png';
import edit1 from '../../../assets/icons/edit.png';
import up2 from '../../../assets/icons/Polygon 1.png';
import down2 from '../../../assets/icons/Polygon 2.png';
import delete1 from '../../../assets/icons/trash.png';
import add from '../../../assets/icons/add-button.svg';
import folder from '../../../assets/icons/folder-open.svg';
import downloadIcon from '../../../assets/icons/download-icon.png'



import ModalStep3 from './ModalStep3';
import "../style.css";
import { controller } from "../controller";

const { Title } = Typography;

const TreatmentCardStep2 = (props) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [data1, setData1] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [currentStep, setCurrentStep] = useState(1);
    const [item, setItems] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [note, setNote] = useState("");
    const [imageFile, setImageFile] = useState([]);
    const [selectedTreatmentIds, setSelectedTreatmentIds] = useState([]);
    const [edit, setEdit] = useState({});
    const [isModalVisible1, setIsModalVisible1] = useState(false);
    const [updatingPriority, setUpdatingPriority] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [visible, setVisible] = useState(true);
    const [selectedPatientId, setSelectedPatientId] = useState(null);
    const [data, setData] = useState([]);
    const [save, setSave] = useState(false);



    const getImageUrl = file => {
        if (file instanceof File) {
            return URL.createObjectURL(file);
        }
        return file.image_path || URL.createObjectURL(file);
    };


    const handleTextAreaChange = (e) => {
        setNote(e.target.value);
    };




    const showModal = () => {
        setSelectedPatientId(props.data.id);
        setIsModalVisible1(true);
        console.log(isModalVisible1);
    };

    useEffect(() => {
        if (!isExpanded) {
            setNote("");
            setImageFile([]);
        }
    }, [isExpanded]);

    const Priority = ({ loading, initialPriority, onUpdate, record }) => {
        const [count, setCount] = useState(Number(initialPriority));

        const increasePriority = async () => {
            const newPriority = Number(count) + 1;
            setCount(newPriority);
            onUpdate(newPriority, record);
        };

        const decreasePriority = async () => {
            const newPriority = Math.max(Number(count) - 1, 0);
            setCount(newPriority);
            onUpdate(newPriority, record);
        };

        useEffect(() => {
            setCount(initialPriority);
        }, [initialPriority]);

        return (
            <div className="div-prority">
                <Button type="text" icon={<img src={down2} alt="" />} onClick={decreasePriority} />
                {
                    loading ? <Spin size="small" /> :
                        <p className="p-fontSize"> {count} </p>
                }
                <Button type="text" icon={<img src={up2} alt="" />} onClick={increasePriority} />
            </div>
        );
    };


    const handleDelete2 = async (imageId, index) => {
        try {
            if (imageId) {
                await controller.removeImages(imageId);
                setImageFile(prevFiles => prevFiles.filter(file => file.id !== imageId));
            } else {

                setImageFile(prevFiles => {
                    const updatedFiles = prevFiles.filter(file => file.id !== imageId);
                    console.log("Updated files after deletion:", updatedFiles);
                    return updatedFiles;
                });
                console.log(imageFile)
                window.location.reload()
            }
        } catch (error) {
            console.error("Error deleting image:", error);
        }
    };

    useEffect(() => {
        console.log("Current image files in state:", imageFile);
        // Any other operations that depend on updated imageFile
    }, [imageFile]); // Dependency array to re-run this effect when imageFile changes





    const handleUploadChange = (info) => {
        setImageFile(prevFiles => {
            const newFiles = info.fileList.map(file => file.originFileObj);

            const combinedFiles = [...prevFiles, ...newFiles.filter(newFile => !prevFiles.some(prevFile => prevFile.name === newFile.name && prevFile.lastModified === newFile.lastModified))];

            return combinedFiles;
        });
    };


    // const urlToFile = async (url, filename, mimeType) => {
    //     const response = await fetch(url);
    //     const blob = await response.blob();
    //     return new File([blob], filename, { type: mimeType });
    // };

    // function fileToBase64(file) {
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.readAsDataURL(file);
    //         reader.onload = () => resolve(reader.result);
    //         reader.onerror = error => reject(error);
    //     });
    // }

    // // Function to preview image
    // async function previewImage(file) {
    //     try {
    //         const base64Image = await fileToBase64(file);
    //         // Create an image element for preview
    //         const img = document.createElement('img');
    //         img.src = base64Image;
    //         img.alt = 'Preview Image';
    //         img.style.maxWidth = '200px';
    //         document.body.appendChild(img);
    //     } catch (error) {
    //         console.error('Error in previewing image', error);
    //     }
    // }



    const handleReadData = async () => {
        var id = localStorage.getItem('patient_id');
        try {
            const response = await controller.getDoctorNote(id);

            if (response.status < 250) {
                const jsonData = await response.json;


                if (jsonData.results && jsonData.results.length > 0) {
                    // Extract the IDs
                    const ids = jsonData.results.map(result => result.id);

                    const largestId = Math.max(...ids);
                    const largestIdData = jsonData.results.find(result => result.id === largestId);

                    setData(largestIdData);
                    setNote(largestIdData.note);


                    if (largestIdData.image_files && largestIdData.image_files.length > 0) {

                        const imageData = largestIdData.image_files.map(file => ({
                            path: file.image_path,
                            id: file.id
                        }));
                        setImageFile(imageData);
                        console.log("Image data:", imageData);
                    } else {
                        console.error('No image file found in the response');
                        setImageFile([]);
                    }


                    const treatmentIDs = largestIdData.treatment_plans.map(plan => plan.id);
                    setSelectedTreatmentIds(treatmentIDs);
                    setSelectedRowKeys(treatmentIDs.map(id => id.toString()));
                } else {
                    console.error('No note found in the response');
                    setSave(true);
                    setImageFile([]);
                }
            }
        } catch (e) {
            console.error('Error fetching the note:', e);
            // Optionally handle the error, e.g., display a notification
            setImageFile([]); // Ensure imageFile is an empty array if an error occurs
        }
    };








    useEffect(() => {
        handleReadData();
    }, []);

    useEffect(() => {
        setSelectedRowKeys(selectedTreatmentIds.map(id => id.toString()));
    }, [selectedTreatmentIds]);


    const handleReadDataNoLoading = async () => {
        try {
            const response = await controller.getTreatmentPlans("not_approved", " ");
            if (response.status < 250) {
                const data = await response.json;
                setData1(data);
                console.log(data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleSuccessAddToServer = () => {
        props.updateData();
    }

    useEffect(() => {
        handleReadDataNoLoading();
    }, []);

    const processTreatmentPlans = () => {
        if (props.data && props.data.treatment_plans) {
            const treatmentPlans = props.data.treatment_plans.map(plan => ({
                key: plan.id.toString(),
                treatment: plan.name || "-",
                estimatedCost: plan.estimated_cost || ' ',
                insuranceCoverage: plan.insurance_coverage || " ",
                notes: plan.description || "-",
                priority: plan.priority ? plan.priority : 0,
                procedure: plan.procedure ? plan.procedure.name : "-"
            }));

            setTableData(treatmentPlans);
            console.log("Treatment Plans:", treatmentPlans);

            const initialEditState = {};
            treatmentPlans.forEach(row => {
                initialEditState[row.key] = false;
            });

            setEdit(initialEditState);
        }
    };

    useEffect(() => {
        console.log("props.data has changed:", props.data);
        processTreatmentPlans();
    }, [props.data]);

    const handleNoteChange = (e, record) => {
        const updatedTableData = tableData.map(row => {
            if (row.key === record.key) {
                return { ...row, notes: e.target.value };
            }
            return row;
        });
        setTableData(updatedTableData);
    };

    const handleNameChange = (e, record) => {
        const updatedTableData = tableData.map(row => {
            if (row.key === record.key) {
                return { ...row, treatment: e.target.value };
            }
            return row;
        });
        setTableData(updatedTableData);
    };

    const handleEstimateChange = (e, record) => {
        const updatedTableData = tableData.map(row => {
            if (row.key === record.key) {
                return { ...row, estimatedCost: e.target.value };
            }
            return row;
        });
        setTableData(updatedTableData);
    };

    const handleInsuranceChange = (e, record) => {
        const updatedTableData = tableData.map(row => {
            if (row.key === record.key) {
                return { ...row, insuranceCoverage: e.target.value };
            }
            return row;
        });
        setTableData(updatedTableData);
    };

    const handleEdit = (record) => {
        const updatedEditState = { ...edit, [record.key]: !edit[record.key] };
        setEdit(updatedEditState);
    };

    const cancelEdit = async (record) => {
        await saveNotesToServer(record);
        setEdit(false);
    };

    const saveNotesToServer = async (record) => {
        try {
            if (!record.notes || record.notes.trim() === '') {
                return;
            }
            await controller.UpdateTreatmentPlans(record.key, { description: record.notes, name: record.treatment, estimated_cost: record.estimatedCost, insurance_coverage: record.insuranceCoverage });
            props.updateDataUpdatePriority();
        } catch (error) {
            console.error("Error updating notes:", error);
        }
    };

    const savePriorityToServer = async (record) => {
        setUpdatingPriority(record.key);
        try {
            await controller.UpdateTreatmentPlans(record.key, {
                priority: record.priority
            });
            setTableData(prevTableData =>
                prevTableData.map(row =>
                    row.key === record.key ? { ...row, priority: record.priority } : row
                )
            );
            props.updateDataUpdatePriority();
        } catch (error) {
            console.error("Error updating priority:", error);
        }
        setTimeout(() => {
            setUpdatingPriority(null);
        }, 800);
    };

    const handleDelete = async (record) => {
        try {
            await controller.RemoveTreatmentPlans(record.key);
            const updatedTableData = tableData.filter(row => row.key !== record.key);
            setTableData(updatedTableData);
            window.location.reload()
        } catch (error) {
            console.error("Error deleting treatment plan:", error);
        }
    };

    const handleVisible = async (record, visibility) => {
        try {
            await controller.visibleTreatmentPlans(record.key, { visible_to_patient: visibility });

            const updatedTableData = tableData.map(row => {
                if (row.key === record.key) {
                    return { ...row, visible: visibility };
                }
                return row;
            });

            setTableData(updatedTableData);

            notification.success({
                message: 'Success',
                description: `Visibility for ${record.treatment} updated successfully.`,
                placement: 'bottomRight',
            });
        } catch (error) {
            console.error("Error updating visibility:", error);
            notification.error({
                message: 'Error',
                description: `Error updating visibility for ${record.treatment}.`,
                placement: 'bottomRight',
            });
        }
    };

    const renderEditCell = (record) => {
        const isEditing = edit[record.key];
        return (
            <span>
                {isEditing ? (
                    <Input.TextArea
                        style={{ border: "1px solid #C9C1F1" }}
                        value={record.notes}
                        onChange={(e) => handleNoteChange(e, record)}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                    />
                ) : (
                    <span>{record.notes}</span>
                )}
            </span>
        );
    };

    const renderEditCell1 = (record) => {
        const isEditing = edit[record.key];
        return (
            <span>
                {isEditing ? (
                    <Input.TextArea
                        style={{ border: "1px solid #C9C1F1" }}
                        value={record.treatment}
                        onChange={(e) => handleNameChange(e, record)}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                    />
                ) : (
                    <span>{record.treatment}</span>
                )}
            </span>
        );
    };
    const renderEditCell2 = (record) => {
        const isEditing = edit[record.key];
        return (
            <span>
                {isEditing ? (
                    <Input.TextArea
                        style={{ border: "1px solid #C9C1F1" }}
                        value={record.estimatedCost}
                        onChange={(e) => handleEstimateChange(e, record)}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                    />
                ) : (
                    <span>{record.estimatedCost}</span>
                )}
            </span>
        );
    };
    const renderEditCell3 = (record) => {
        const isEditing = edit[record.key];
        return (
            <span>
                {isEditing ? (
                    <Input.TextArea
                        style={{ border: "1px solid #C9C1F1" }}
                        value={record.insuranceCoverage}
                        onChange={(e) => handleInsuranceChange(e, record)}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                    />
                ) : (
                    <span>{record.insuranceCoverage}</span>
                )}
            </span>
        );
    };

    // const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
    //     setSelectedRowKeys(newSelectedRowKeys);
    //     const ids = newSelectedRows.map(row => row.key);
    //     setSelectedTreatmentIds(ids);
    //     console.log('Selected Treatment IDs:', ids);
    // };

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys) => {
            setSelectedRowKeys(newSelectedRowKeys);
            const ids = newSelectedRowKeys.map(key => parseInt(key, 10));
            setSelectedTreatmentIds(ids);
            console.log('Selected Treatment IDs:', ids);
        },
    };



    // const handleApprove = async () => {
    //     if (selectedTreatmentIds.length === 0) {
    //         notification.error({
    //             message: 'Error',
    //             description: 'Please select at least one treatment plan.',
    //             placement: 'bottomRight',
    //         });
    //         return; 
    //     }

    //     setIsLoading(true);
    //     try {
    //         const formData = new FormData();
    //         for (var i in selectedTreatmentIds) {
    //             formData.append('treatment_plans', selectedTreatmentIds[i]);
    //         }
    //         formData.append('note', note);
    //         formData.append("patient", props.data.id);
    //         imageFile.forEach((file, index) => {
    //             if (file instanceof File || file instanceof Blob) {
    //                 formData.append(`image_files`, file);
    //             }
    //         });
    //         const response = await controller.postNoteAndImage(formData, 'sent');
    //         if (response.status < 250) {
    //             notification.success({
    //                 message: 'Success',
    //                 description: 'Your Files Successfully Sent.',
    //                 placement: 'bottomRight',
    //             });
    //             setIsExpanded(false);
    //             setIsLoading(false);
    //             props.setCurrentStep(1);
    //         }
    //     } catch (error) {
    //         console.error("Error occurred during upload:", error);
    //         setIsLoading(false); 
    //     }
    // };


    const handleApprove2 = async (status) => {
        if (selectedTreatmentIds.length === 0) {
            notification.error({
                message: 'Error',
                description: 'Please select at least one treatment plan.',
                placement: 'bottomRight',
            });
            return;
        }

        if (note === '') {
            notification.error({
                message: 'Error',
                description: 'Please Write Note',
                placement: 'bottomRight',
            });
            return;
        }        

        try {
            if (save) {
                setIsLoading1(true);
                const formData = new FormData();
                for (var i in selectedTreatmentIds) {
                    formData.append('treatment_plans', selectedTreatmentIds[i]);
                }
                formData.append('note', note);
                formData.append("patient", props.data.id);
                imageFile.forEach((file, index) => {
                    if (file instanceof File || file instanceof Blob) {
                        formData.append(`image_files`, file);
                    }
                });
                const response = await controller.postNoteAndImage(formData, 'sent');
                if (response.status < 250) {
                    notification.success({
                        message: 'Success',
                        description: 'Data Updated Successfully.',
                        placement: 'bottomRight',
                    });
                    setIsExpanded(false);
                    setIsLoading1(false);
                    props.setCurrentStep(1);
                }
            } else {
                setIsLoading(true);
                const formData = new FormData();
                for (var j in selectedTreatmentIds) {
                    formData.append('treatment_plans', selectedTreatmentIds[j]);
                }
                formData.append('note', note);
                // formData.append("patient", props.data.id);
                imageFile.forEach((file, index) => {
                    if (file instanceof File || file instanceof Blob) {
                        formData.append(`image_files`, file);
                    }
                });
                formData.append('status', status)
                const response = await controller.UpdateDoctorNote(formData, data.id);
                if (response.status < 250) {
                    const jsonData = await response.json;

                    console.log('Data from another endpoint:', jsonData);
                    notification.success({
                        message: 'Success',
                        description: 'Data Updated Successfully.',
                        placement: 'bottomRight',
                    });
                    setIsExpanded(false);
                    setIsLoading1(false);
                    props.setCurrentStep(1);
                }
                setIsLoading1(false);
                setIsLoading(false)
            }
        } catch (error) {
            console.error("Error occurred during operation:", error);
            setIsLoading1(false);
            setIsLoading(false)
        }
    };




    const columns3 = [
        {
            title: "Priority",
            dataIndex: "priority",
            key: "priority",
            width: '10%',
            sorter: (a, b) => a.priority - b.priority,
            defaultSortOrder: 'ascend',
            render: (priority, record) => (
                record.key !== updatingPriority ?
                    <>
                        <Priority
                            loading={false}
                            initialPriority={priority}
                            onUpdate={(newPriority, record) => {
                                savePriorityToServer({ ...record, priority: newPriority });
                            }}
                            record={record}
                        />
                    </>
                    :
                    <Priority
                        loading={true}
                        initialPriority={priority}
                        onUpdate={(newPriority, record) => {
                            savePriorityToServer({ ...record, priority: newPriority });
                        }}
                        record={record}
                    />
            ),
        },


        {
            title: "Treatment Plan",
            dataIndex: "treatment",
            key: "treatment",
            width: '25%',
            render: (_, record) => renderEditCell1(record),

        },
        {
            title: "Description",
            dataIndex: "notes",
            key: "notes",
            width: '40%',
            render: (_, record) => renderEditCell(record),
        },
        {
            title: "Estimated Cost",
            dataIndex: "estimatedCost",
            key: "estimatedCost",
            width: '25%',
            render: (_, record) => renderEditCell2(record),

        },
        {
            title: "Insurance Coverage",
            dataIndex: "insuranceCoverage",
            key: "insuranceCoverage",
            width: '25%',
            render: (_, record) => renderEditCell3(record),

        },
        {
            title: "Action",
            key: "action",
            width: '5%',
            render: (_, record) => (
                <span>
                    <Space size="middle">

                        {edit[record.key] ? (
                            <Button
                                type="text"
                                icon={<img src={check} alt="" />}
                                style={{ color: "#979797" }}
                                onClick={() => cancelEdit(record)}
                            />
                        ) : (
                            <Button
                                type="text"
                                icon={<img src={edit1} alt="" />}
                                style={{ color: "#979797" }}
                                onClick={() => handleEdit(record)}
                            />
                        )}
                        <Button
                            type="text"
                            icon={<img src={delete1} alt="" />}
                            style={{ color: "#979797" }}
                            onClick={() => handleDelete(record)}
                        />

                        {/* {record.visible ? (
                            <Button
                                type="text"
                                icon={<img src={eyeslash} alt="" />}
                                style={{ color: "#979797" }}
                                onClick={() => handleVisible(record, false)}
                            />
                        ) : (
                            <Button
                                type="text"
                                icon={<img src={eye} alt="" />}
                                style={{ color: "#979797" }}
                                onClick={() => handleVisible(record, true)}
                            />
                        )} */}
                    </Space>
                </span>
            ),
        },
    ];

    return (
        <>
            <ModalStep3
                isModalVisible={isModalVisible1}
                setIsModalVisible={setIsModalVisible1}
                selectedMember={item}
                handleSuccessAddToServer={handleSuccessAddToServer}
                patientId={selectedPatientId}
                handleReadData={handleReadData}
                processTreatmentPlans={() => processTreatmentPlans(props.data)}
            />


            <Title level={4} style={{ marginBottom: 25 }}>{props.data.first_name}'s Treatment Plans</Title>

            <Card>
                <>
                    <Title className="step-title" level={5}>
                        Doctor's Note
                    </Title>
                    <Input.TextArea
                        className="textarea-shadow-border2"
                        value={note}
                        onChange={handleTextAreaChange}
                        placeholder="Write Doctor’s Note..."
                    />
                </>
                <>
                    <Card>
                        <Title className="step-title" level={5}>
                            Treatment Plans
                        </Title>
                        <div className="div-table1">
                            <Table
                                dataSource={tableData}
                                columns={columns3}
                                pagination={false}
                                rowSelection={rowSelection}
                            />
                        </div>
                        <Button
                            className="step1-button"
                            type="default"
                            onClick={showModal}
                        >
                            Add new Treatment Plan
                        </Button>
                    </Card>
                    <label className="inputLabel" style={{ fontSize: 18, fontWeight: '600', marginBottom: 20 }}>
                        Files
                    </label>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {imageFile.map((file, index) => {
                            let urlObject;
                            let pathname = '';
                            let fileType = '';

                            try {
                                urlObject = new URL(file.path);
                                pathname = urlObject.pathname;
                                fileType = pathname.split('.').pop();
                            } catch (e) {
                                pathname = file.name || '';
                                fileType = pathname.split('.').pop();
                            }

                            return (
                                <div key={file.name} className="uploaded-file-preview" style={{ display: 'inline-block', marginRight: 10, position: 'relative' }}>
                                    {file.type && file.type.startsWith('image') ? (
                                        <>
                                            <img
                                                src={getImageUrl(file)}
                                                alt={folder}
                                                style={{ objectFit: 'cover', borderRadius: 8, border: '1px solid #6B43B5', width: 200, height: 133, position: 'relative' }}
                                            />
                                            {/* Download Icon */}
                                            <div className='downloadIconContainer'
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    zIndex: "5"
                                                }}
                                            >
                                                <a href={getImageUrl(file)} download>
                                                    <img
                                                        width={15}
                                                        height={15}
                                                        src={downloadIcon}
                                                        alt="download"
                                                    />
                                                </a>
                                            </div>
                                        </>
                                    ) : (
                                        <div style={{ border: '1px solid #6B43B5', borderRadius: 8, width: 200, height: 133, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                            <div style={{ fontSize: 50, color: '#6B43B5' }}>
                                                {fileType === 'pdf' || fileType === 'msword' || fileType === 'vnd.openxmlformats-officedocument.wordprocessingml.document' || fileType === 'xlsx' || fileType === 'pptx' || fileType === 'zip' || fileType === 'rar' || fileType === 'docx' || fileType === 'aspx' || fileType === 'mp4' || fileType === 'mkv' ?
                                                    <><img src={folder} alt="folder icon" /><div className='downloadIconContainer'
                                                        style={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            zIndex: "5",
                                                            width: 30,
                                                            height: 35
                                                        }}
                                                    >
                                                        <a href={file.path ? file.path : getImageUrl(file)} download style={{ position: 'absolute', bottom: -17 }}>
                                                            <img
                                                                width={15}
                                                                height={15}
                                                                src={downloadIcon}
                                                                alt="download" />
                                                        </a>
                                                    </div></>
                                                    :
                                                    <><img
                                                        src={file.path}
                                                        alt={folder}
                                                        style={{ objectFit: 'cover', width: 200, height: 133, borderRadius: 8, display: 'flex', alignItems: 'center', marginTop: 10 }} />
                                                        <div className='downloadIconContainer'
                                                            style={{
                                                                position: 'absolute',
                                                                top: '50%',
                                                                left: '50%',
                                                                transform: 'translate(-50%, -50%)',
                                                                zIndex: "5",
                                                                width: 30,
                                                                height: 35
                                                            }}
                                                        >
                                                            <a href={file.path} download style={{ position: 'absolute', bottom: -17 }}>
                                                                <img
                                                                    width={15}
                                                                    height={15}
                                                                    src={downloadIcon}
                                                                    alt="download" />
                                                            </a>
                                                        </div></>
                                                }
                                            </div>
                                            <div style={{ fontSize: 12, color: '#6B43B5', marginTop: 10, textAlign: 'center' }}>{file.name}</div>
                                        </div>
                                    )}
                                    <button
                                        onClick={() => handleDelete2(file.id, index)}
                                        style={{
                                            position: 'absolute',
                                            top: 5,
                                            right: 5,
                                            background: 'transparent',
                                            color: '#B7B7B7',
                                            border: 'none',
                                            borderRadius: '50%',
                                            width: 23,
                                            height: 20,
                                            textAlign: 'center',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        x
                                    </button>
                                </div>
                            );
                        })}
                        <Col
                            xs={4}
                            md={4}
                            style={{ padding: 0, border: '1px dashed #B7B7B7', borderWidth: 3, borderRadius: 8, display: 'inline-block', verticalAlign: 'top', marginLeft: 15 }}
                        >
                            <div className="clearfix" style={{ width: 200 }}>
                                <Upload
                                    beforeUpload={() => false}
                                    showUploadList={false}
                                    // fileList={toFileList(imageFile)}
                                    onChange={handleUploadChange}
                                >
                                    <label
                                        className='formLabel'
                                        style={{
                                            color: "gray",
                                            backgroundColor: "none",
                                            display: "flex",
                                            height: "81px",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: '8px',
                                            cursor: "pointer",
                                            maxWidth: "150px",
                                            minWidth: "unset",
                                            padding: "15px",
                                            flexDirection: 'column',
                                            fontSize: 14,
                                            marginLeft: 35
                                        }}
                                    >
                                        <div style={{ top: -15, position: 'relative' }}>
                                            <img src={add} alt='' style={{}} />
                                        </div>
                                        <div style={{ color: '#B7B7B7', fontSize: 12, marginBottom: 5 }}>
                                            Drag and drop or <span style={{ color: '#6B43B5' }}>Browse</span> your files
                                        </div>
                                    </label>
                                </Upload>
                            </div>
                        </Col>
                    </div>

                </>
            </Card>
            <div className="step2-div" style={{ marginBottom: 100 }}>
                <Row style={{ marginLeft: 'auto' }}>
                    <Button
                        className="render-btn2"
                        onClick={() => handleApprove2("draft")}
                        // loading={isLoading1}
                        style={{ marginRight: 10, border: '1px solid #6B43B5', color: '#6B43B5' }}
                    // disabled={!selectedTreatmentIds || selectedTreatmentIds.length === 0}
                    >
                        Save
                    </Button>
                    <Button
                        className="render-btn2"
                        type="primary"
                        onClick={() => handleApprove2("sent")}
                    // loading={isLoading}
                    // disabled={!selectedTreatmentIds || selectedTreatmentIds.length === 0}
                    >
                        Send
                    </Button>
                </Row>

            </div>


        </>
    );
};

export default TreatmentCardStep2;
