import { Table, Typography, ConfigProvider, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import {
    Row,
    Modal,
    Card,
    Button,
    Col,
    Popover
} from "antd"

// icons
import modalTitleICon from "../assets/icon/radar.png"

const { Text } = Typography;

const VideoPlayer = ({ videoUrl }) => {
    return (
        <div style={{ borderRadius: '8px', overflow: 'hidden', height: "150px" }}>
            <iframe
                title="videoPlayer"
                width="100%"
                src={videoUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    );
};

const TreatmentPlanTable = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [showModalID, setShowModalID] = useState(false)
    const [data, setData] = useState([])

    const handleShowModal = (record) => {
        console.log(record.key)
        setShowModalID(record.key)
        setShowModal(true)
    }

    useEffect(() => {
        if (props.data) {
            // Flatten the data to include each treatment plan as a separate row
            const flattenedData = props.data.flatMap(item => 
                item.treatment_plans.map(tp => ({
                    key: `${item.id}-${tp.id}`,
                    patient: item.patient,
                    note: item.note,
                    status: item.status,
                    created_date: item.created_date,
                    updated_date: item.updated_date,
                    ...tp
                }))
            );
            setData(flattenedData);
        }
    }, [props.data]);

    const columns = [
        {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority',
            sorter: (a, b) => a.priority - b.priority,
            defaultSortOrder: 'ascend',
            width: 80,
            render: (priority) => (
                <Row justify={"center"}>
                    {priority !== null ? priority : "-"}
                </Row>
            ),
        },
        {
            title: 'Treatment Description',
            dataIndex: 'name',
            key: 'name',
            width: 250,
            render: (name) => (
                <Row justify={name ? "" : "center"}>
                    {name ? name : "-"}
                </Row>
            ),
        },
        {
            title: 'Notes',
            dataIndex: 'description',
            key: 'description',
            render: (description) => (
                <Row justify={description ? "" : "center"}>
                    {description ? description : "-"}
                </Row>
            ),
        },
        {
            title: "Estimated Cost",
            dataIndex: "estimated_cost",
            key: "estimated_cost",
            width: '25%',
            render: (estimated_cost) => (
                <Row justify={estimated_cost ? "" : "center"}>
                    {estimated_cost ? parseFloat(estimated_cost).toFixed(2) : "-"}
                </Row>
            )
        },
        {
            title: "Insurance Coverage",
            dataIndex: "insurance_coverage",
            key: "insurance_coverage",
            width: '25%',
            render: (insurance_coverage) => (
                <Row justify={insurance_coverage ? "" : "center"}>
                    {insurance_coverage ? insurance_coverage : "-"}
                </Row>
            )
        },
        {
            title: 'Patient Education',
            dataIndex: 'video_link',
            key: 'video_link',
            width: 180,
            render: (video_link, record) =>
                <Popover
                    showArrow={false}
                    placement='bottomRight'
                    content={() => (
                        <div className='card-in-modal'>
                            <div className='insideCardModal'>
                                <Typography style={{ fontSize: "12px", fontWeight: "500" }}>
                                    Learn More:
                                </Typography>
                                <br />
                                <Row align={"middle"}>
                                    <Col>
                                        <img height={35} width={30} src={modalTitleICon} alt="radar" className='mr10' />
                                    </Col>
                                    <Col>
                                        <Typography style={{ fontSize: "11px", fontWeight: "500", color: "#6B43B5" }}>
                                            {record.procedure && record.procedure.name}
                                        </Typography>
                                        <Typography style={{ fontSize: "8px", fontWeight: "500", color: "#979797" }}>
                                            Procedure Code:  {record.procedure && record.procedure.procedure_code}
                                        </Typography>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Typography style={{ fontSize: "9px", fontWeight: "400", color: "#979797", textAlign: "justify" }}>
                                        {record.procedure && record.procedure.notes}
                                    </Typography>
                                </Row>
                                <br />
                                <div>
                                    <VideoPlayer videoUrl={video_link} />
                                </div>
                                <br />
                                <div>
                                    <Row justify={"center"}>
                                        <Button type="primary" style={{ width: "240px", height: '26px', fontSize: '10px', fontWeight: '400' }}>
                                            <span style={{ fontSize: "12px" }}>
                                                Request Appointment
                                            </span>
                                        </Button>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    )} trigger="click">
                    <Text onClick={() => { handleShowModal(record) }} underline style={{ color: '#6B43B5', textDecoration: "underline", cursor: "pointer" }}>Learn More</Text>
                </Popover>
        },
    ];

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: "#983cfc",
                    controlItemBgHover: "#c293ff",
                    colorLink: '#983cfc',
                },
                components: {
                    Table: {
                        borderRadius: "8px",
                        borderColor: "#eee",
                    },
                },
            }}
        >
            <Table dataSource={data} columns={columns} bordered pagination={false} />
        </ConfigProvider>
    );
}

export default TreatmentPlanTable;
